import React from "react"
import SEO from "../components/seo"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Header from "../components/Header"
import Layout from "../components/layout"
import Kancelaria from "../components/kancelaria"
import Testimonials from "../components/Testimonials"
import BusinessOffer from "../components/BusinessOffer"
import { Helmet } from "react-helmet"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Navigation from "../components/navigation/Navigation"
import SectionDivider from "../components/Divider/SectionDivider"
import * as data from "../TextFiles/Divider.json"
import favicon from "../images/favicon.jpg"
const IndexPage = () => (
  <Layout>
    <Helmet>
      <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"></script>
      <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"></script>
    </Helmet>
    <SEO
      link={[{ rel: "shortcut icon", type: "image/jpg", href: `${favicon}` }]}
    />
    <Navigation />
    <Header />
    <Kancelaria />
    <SectionDivider
      title={data.sentence1.title}
      subtitle={data.sentence1.subtitle}
    />
    <BusinessOffer />
    <Testimonials />
    <SectionDivider
      title={data.sentence3.title}
      subtitle={data.sentence3.subtitle}
    />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
