import React from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import ModalHeader from "react-bootstrap/ModalHeader"
import ModalTitle from "react-bootstrap/ModalTitle"
import ModalBody from "react-bootstrap/ModalBody"
import ModalFooter from "react-bootstrap/ModalFooter"
import styled from "@emotion/styled"
import { COLORS } from "../../styles/constants"

const ButtonWrapper = styled.div`
  .my-btn {
    background-color: ${COLORS.mainBlue};
    :active {
      background-color: ${COLORS.mainBlue} !important;
    }
  }
  `

const Paragraph = styled.p`
  text-align: justify;
`

const Table = styled.table`
  text-align: justify;
  p {
    margin-bottom: 0;
  }

  .paragraph-inside {
    margin-left: 20px;
  }
`

export class MyVerticallyCenteredModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader closeButton>
          <ModalTitle id="contained-modal-title-vcenter">
            {this.props.modalheading}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Paragraph>{this.props.description}</Paragraph>
          {this.props.thead && (
            <Table
              id="first-table"
              className="table table-hover table-bordered"
            >
              <thead>
                <tr>
                  <td>{this.props.thead}</td>
                </tr>
              </thead>
              <tbody>
                {this.props.tbody.map((element, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <p className="paragraph-inside">{element}</p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
          {this.props.secondtable && (
            <Table
              id="second-table"
              className="table table-hover table-bordered"
            >
              <tbody>
                {this.props.secondtable.map((element, index) => {
                  return (
                    <tr key={index}>
                      <td>{element}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <Button className="my-btn" onClick={this.props.onHide}>
              Zamknij
            </Button>
          </ButtonWrapper>
        </ModalFooter>
      </Modal>
    )
  }
}
