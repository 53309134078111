import React from "react"
import styled from "@emotion/styled"
import { device } from "../../styles/theme"

export const ContainerCard = styled.div`
  padding-bottom: 45px;

  .card-title {
    font-weight: 600;
  }

  button {
    -webkit-transition: 0.25s all;
    transition: 0.25s all;
  }

  .card-block {
    margin-top: 50px;
  }
  .card {
    @media ${device.mobile} {
      margin-bottom: 30px;
    }
    --borderWidth: 4px;
    position: relative;
    border-radius: var(--borderWidth);
    :after {
      content: "";
      position: absolute;
      top: calc(-1 * var(--borderWidth));
      left: calc(-1 * var(--borderWidth));
      height: calc(100% + var(--borderWidth) * 2);
      width: calc(100% + var(--borderWidth) * 2);
      background: linear-gradient(
        60deg,
        #00d4ff,
        #f37055,
        #ef4e7b,
        #a166ab,
        #5073b8,
        #1098ad,
        #07b39b,
        #090979
      );
      border-radius: calc(2 * var(--borderWidth));
      z-index: -1;
      animation: animatedgradient 3s ease alternate infinite;
      background-size: 300% 300%;
    }
    @keyframes animatedgradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-left: auto;
    padding-right: auto;
    min-height: 300px;
    margin-top: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -webkit-transition: 0.25s box-shadow;
    transition: 0.25s box-shadow;
  }
  .card:focus,
  .card:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
  .card-inverse .card-img-overlay {
    background-color: rgba(51, 51, 51, 0.85);
    border-color: rgba(51, 51, 51, 0.85);
  }
  .card-img-top {
    margin-top: 10px;
  }
  .col-xs-12 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .row {
    margin-left: auto;
    margin-right: auto;
  }
`
export default function Card(props) {
  return (
    <div className="col-xs-12 col-md-6 col-lg-4">
      <div className="card">
        <div className="card-block">
          <h4 className="card-title">{props.title}</h4>
          <p className="card-text">{props.descriptionCard}</p>
          <button href="#" className="btn btn-danger" onClick={props.onClick}>
            {props.button}
          </button>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  )
}
