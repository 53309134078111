import React from "react"
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaPhone,
  FaAddressCard,
  FaGetPocket,
  FaMailBulk,
} from "react-icons/fa"
import styled from "@emotion/styled"
import { COLORS } from "../styles/constants"
import Fade from "react-reveal/Fade"
import { device } from "../styles/theme"
import { Link } from "gatsby"

const ContactUsForm = styled.div`
  @media ${device.mobile} {
    text-align: center;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 21px;
    color: #555;
    background-color: transparent;
    border: 1px solid #aeaeae;
    border-radius: 3px;
    margin-bottom: 15px;
    opacity: 0.8;
    outline: 0px !important;
    transition: all 1s;
  }
  #message {
    height: 117px;
    resize: none;
  }

  .map-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  .map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .contact-paragraph {
    font-weight: 400;
    @media ${device.mobile} {
      font-size: 1em;
    }
    a {
      color: #212529 !important;
      text-decoration: none;
    }
  }

  .title {
    text-transform: uppercase;
  }

  .title-name {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    position: relative;
    margin-bottom: 30px;
  }

  .contact-details-wrapper {
    line-height: 36px;
    display: flex;
    .icon-style {
      font-size: 25px;
      margin-right: 10px;
    }
  }
  .mobile-p {
    @media ${device.mobile} {
      word-break: break-all;
    }
  }
`

const SocialIcons = styled.div`
  padding-top: 50px;
  padding-bottom: 70px;
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      list-style: none;
      display: inline;
      padding-left: 10px;

      a {
        text-decoration: none;
        display: inline-block;
        text-align: center;
        border: 1px solid #9d9d9d9d;
        border-radius: 3px;
        width: 45px;
        height: 45px;
        line-height: 39px;
        color: #9d9d9d;
        font-size: 20px;
        transition: all 1s;
        &:hover {
          background-color: ${COLORS.mainBlue};
          color: white;
        }
      }
    }
  }
`

const ContactWrapper = styled.section`
  padding: 60px;
`
export default function Contact() {
  return (
    <ContactWrapper id="kontakt" className="contact-us">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="section-title">
              <h2>Kontakt</h2>
              <p className="text-center font-weight-bold">
                Spotkania z klientami odbywają się w Słupsku oraz w Gdańsku.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ContactUsForm>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Fade left>
                <h5 className="title">Kancelaria radcy prawnego</h5>
                <h2 className="title-name">Paweł Jurczyński</h2>
                <div className="contact-details-wrapper">
                  <span>
                    <FaAddressCard className="icon-style" />
                  </span>
                  <p className="contact-paragraph lead">
                    ul. Stefana Żeromskiego 4/A, 76-200 Słupsk, NIP: 5742039649
                  </p>
                </div>
                <div className="contact-details-wrapper">
                  <span>
                    <FaPhone className="icon-style" />
                  </span>
                  <p className="contact-paragraph lead">
                    <a href="tel:48 534 089 846">+ 48 534 089 846</a>
                  </p>
                </div>
                <div className="contact-details-wrapper">
                  <span className="icon-style">
                    <FaMailBulk />
                  </span>
                  <p className="contact-paragraph lead mobile-p">
                    <a href="mailto:pawel@jurczynskikancelaria.pl">
                      pawel@jurczynskikancelaria.pl
                    </a>
                  </p>
                </div>
                <div className="contact-details-wrapper">
                  <span className="icon-style">
                    <FaGetPocket />
                  </span>
                  <p className="contact-paragraph lead mobile-p">
                    <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
                  </p>
                </div>
              </Fade>
            </div>

            <div className="col-md-6">
              <Fade right>
                <div className="map-responsive">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18019.79545809939!2d17.010798351963093!3d54.472166155291255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fe10a8db19c489%3A0xfc926091d2da4e79!2sStefana%20%C5%BBeromskiego%204%2C%2076-200%20S%C5%82upsk!5e0!3m2!1spl!2spl!4v1609330064874!5m2!1spl!2spl"
                    width="549"
                    height="364"
                    loading="lazy"
                    frameBorder="0"
                    style={{ border: "3px solid #e5e8ea" }}
                    allowFullScreen
                  ></iframe>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </ContactUsForm>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Fade up>
              <SocialIcons>
                <ul>
                  <li>
                    <a
                      className="fb"
                      target="_blank"
                      href="https://www.facebook.com/pg/Kancelaria-Radcy-Prawnego-Pawe%C5%82-Jurczy%C5%84ski-109390080484095"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/pawe%C5%82-jurczynski-1251729a/"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a className="twitter" href="#">
                      <FaTwitter />
                    </a>
                  </li>
                </ul>
              </SocialIcons>
            </Fade>
          </div>
        </div>
      </div>
    </ContactWrapper>
  )
}
