import React from "react"
import styled from "@emotion/styled"
import Paragraf from "../images/portfolio-me.jpg"
import { COLORS } from "../styles/constants"
import Fade from "react-reveal/Fade"
import { device } from "../styles/theme"
import * as data from "../TextFiles/kancelaria"
const ChooseUsWrapper = styled.div`
  padding-top: 50px;

  .about-kancelaria {
    img {
      box-shadow: -14px -12px 47px -2px rgba(0, 0, 0, 0.31);
      border-radius: 2%;
    }
    @media ${device.mobile} {
      margin-bottom: 45px;
    }
  }
`

const ChooseUsTitle = styled.div`
  @media ${device.desktop} {
    margin-left: 35px;
  }
  @media ${device.mobile} {
    text-align: center;
  }
  padding-bottom: 50px;
  .title-desc {
    text-transform: uppercase;
  }
  .content-desc {
    font-size: 19px;
    margin-top: 40px;
    line-height: 24px;
    margin-bottom: 65px;
  }

  h2 {
    @media ${device.mobile} {
      display: flex;
      justify-content: center;
    }

    text-transform: uppercase;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    position: relative;
    &::after {
      @media ${device.mobile} {
        display: flex;
        justify-content: center;
        left: unset;
      }
      content: "";
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 286px;
      height: 2.5px;
      background-color: ${COLORS.mainBlue};
      margin-bottom: 6px;
    }
  }
`

export default function Kancelaria() {
  return (
    <section id="kancelaria">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-sm-1">
            <div className="section-title">
              <h2>Kancelaria</h2>
              <p>{data.title}</p>
              <p>{data.title2}</p>
              <p>{data.title3}</p>
              <p>{data.title4}</p>
              <p>{data.title5}</p>
              <p>{data.title6}</p>
              <p>{data.title7}</p>
            </div>
          </div>
        </div>
      </div>
      <ChooseUsWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 col-sm-9">
              <div className="about-kancelaria">
                <img src={Paragraf} className="img-fluid" alt="waga" />
              </div>
            </div>
            <div className="col-md-5">
              <Fade right>
                <ChooseUsTitle>
                  <h2>Paweł Jurczyński</h2>
                  <p className="title-desc">Kancelaria radcy prawnego</p>
                  <div className="content-desc">
                    <p className="lead paragraph-justify">{data.paragraph}</p>
                    <p className="paragraph-justify">{data.paragraph2}</p>
                    <p className="paragraph-justify">{data.paragraph3}</p>
                    <p className="paragraph-justify">{data.paragraph4}</p>
                    <p className="paragraph-justify">{data.paragraph5}</p>
                  </div>
                </ChooseUsTitle>
              </Fade>
            </div>
          </div>
        </div>
      </ChooseUsWrapper>
    </section>
  )
}
