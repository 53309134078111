const size = {
  mobile: "320px",
  tablet: "768px",
  desktop: "1024px"
}

export const device = {
           mobile: `(max-width: ${size.tablet})`,
           tablet: `(max-width: ${size.desktop})`,
           desktop: `(min-width: ${size.desktop})`,
       }
