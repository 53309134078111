import React, { Component } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "./lightbox.scss"
import ref1 from "../../images/ref1.jpg"
import ref2 from "../../images/ref2.jpg"
import ref3 from "../../images/ref3.jpg"
import ref4 from "../../images/ref4.png"
import kwal1 from "../../images/kwal1.jpg"
import kwal2 from "../../images/kwal2.jpg"
import kwal3 from "../../images/kwal3.jpg"
import kwal4 from "../../images/kwal4.jpg"
import kwal5 from "../../images/kwal5.jpg"

const images = [ref4, ref1, ref2, ref3, kwal1, kwal2, kwal3, kwal4, kwal5]

export class LightBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state

    return (
      <>
        <div
          className="lightBtnContainer"
          onClick={() => this.setState({ isOpen: true })}
        >
          <a className="lightBoxButton">Dowiedz się więcej</a>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </>
    )
  }
}
