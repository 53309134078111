import React from 'react'
import styled from "@emotion/styled"
import { COLORS } from "../../styles/constants"


const DividerWrapper = styled.section`
margin-top: 130px;
  position: relative;
  padding: 3.9em 10%;
  color: #fff;
  text-align: center;
  width: 100%;
  background-color: ${COLORS.mainBlue};

  ::before,
  ::after {
    position: absolute;
    content: "";
    pointer-events: none;
  }

  /***/

  ::before {
    bottom: -50px;
    left: 50%;
    width: 100px;
    height: 100px;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    background-color: ${COLORS.mainBlue};
    z-index: 1;
  }
  ::after {
    top: -50px;
    left: 50%;
    width: 100px;
    height: 100px;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    background-color: ${COLORS.mainBlue};
    z-index: 1;
  }
`



export default function SectionDivider(props) {
    return (
      <DividerWrapper>
        <h5>{props.title}</h5>
        <p>
          {props.subtitle}
        </p>
      </DividerWrapper>
    )
}
