import {
  FaBalanceScale,
  FaEdit,
  FaMicrophoneSlash,
  FaBlackTie,
  FaStreetView,
  FaHome,
  FaShoppingBasket,
} from "react-icons/fa"
import { React } from "react"
export const BusinessOffers = [
  {
    title: "Umowy i regulaminy",
    icon: <FaEdit />,
    desc:
      "Przygotowujemy, opiniujemy oraz negocjujemy projekty umów cywilnych i gospodarczych, w tym umów dotyczących: sprzedaży towarów, najmu, przeniesienia praw autorskich, udzielenia licencji, wdrożenia systemów IT, a także wszelkiego rodzaju regulaminy oraz ogólne warunki umów.",
  },
  {
    title: "Spółki handlowe",
    icon: <FaBlackTie />,
    desc:
      "Doradzamy przy wyborze optymalnej formy prawnej prowadzonej działalności gospodarczej. Służymy radą w sprawach związanych z bieżącym funkcjonowaniem spółek handlowych. Pomagamy je zakładać, przekształcać, łączyć, dokonywać ich podziału lub likwidacji. Sporządzamy dokumenty korporacyjne, a także zapewniamy pomoc przy organizacji zgromadzeń wspólników czy też walnych zgromadzeń akcjonariuszy.",
  },
  {
    title: "Spory sądowe",
    icon: <FaBalanceScale />,
    desc:
      "Ustalamy strategię procesową, biorąc pod uwagę jej efektywność, skutki finansowe oraz czas realizacji. Sporządzamy profesjonalne pisma procesowe oraz środki zaskarżenia. Rzetelnie zastępujemy klientów na sali sądowej oraz w postępowaniu egzekucyjnym. Reprezentujemy klientów w postępowaniach cywilnych, administracyjnych, sądowoadministracyjnych, upadłościowych oraz restrukturyzacyjnych.",
  },
  {
    title: "Nieruchomości",
    icon: <FaHome />,
    desc:
      "Przygotowujemy, opiniujemy oraz negocjujemy projekty umów związanych z nieruchomościami (m.in. umowy przeniesienia własności, deweloperskie, przedwstępne, przyrzeczone, ustanowienia ograniczonych praw rzeczowych), analizujemy stan prawny nieruchomości, reprezentujemy klienta w postępowaniach sądowych i administracyjnych związanych z nieruchomościami, pozwoleniami na budowę itp.",
  },
  {
    title: "E-commerce",
    icon: <FaShoppingBasket />,
    desc:
      "Przygotowujemy kompleksowo firmę do działalności e-commerce, czyli sprzedaży lub świadczenia usług z wykorzystaniem Internetu. Dokonujemy analizy profilu działalności firmy, sporządzamy umowy, regulaminy lub ogólne warunki umów zgodne z prawami konsumenta, prawem ochrony danych osobowych, prawem telekomunikacyjnym czy też prawem autorskim.",
  },
  {
    title: "RODO",
    icon: <FaMicrophoneSlash />,
    desc:
      "Zapewniamy kompleksową obsługę procesu wdrażania ogólnego rozporządzenia o ochronie danych osobowych (RODO) w organizacji. Przeprowadzamy audyty identyfikujące ryzyka prawne w tej materii, przygotowujemy dokumentację wymaganą przepisami prawa, doradzamy w bieżącej działalności, a także prowadzimy szkolenia.",
  },
  {
    title: "Audyty",
    icon: <FaStreetView />,
    desc:
      "Dokonujemy profesjonalnej identyfikacji oraz oceny ryzyk prawnych związanych z nabywaniem nieruchomości, akcji lub udziałów w spółkach handlowych oraz przedsiębiorstw. Sporządzamy profesjonalne raporty, w których w przystępny sposób informujemy o potencjalnych konsekwencjach prawnych przeprowadzanych transakcji majątkowych.",
  },
]

export const BusinessCard = [
  {
    id: 10,
    title: "Pakiet „Start-up”",
    descriptionCard:
      "Oferta jest dedykowana dla nowo powstałych firm, w tym start-up’ów. Korzyści, jakie otrzymuje nasz klient:",
    button: "Czytaj więcej",
    thead:
      "1.	Pakiet usług prawnych niezbędnych do rozpoczęcia działalności gospodarczej, tj.:",
    tbody: [
      "A. pomoc w wyborze optymalnej formy działalności gospodarczej oraz pełna obsługa korporacyjna (m.in.: tworzenie spółek cywilnych i handlowych, przygotowywanie dokumentów korporacyjnych, doradztwo w zakresie funkcjonowania podmiotu gospodarczego),",
      "B.	wdrożenie przepisów o ochronie danych osobowych („RODO”) w organizacji (m.in.: analiza zakresu i sposobów przetwarzania danych osobowych w przedsiębiorstwie, sporządzanie odpowiedniej dokumentacji prawnej, przeprowadzania szkoleń),",
      "C.	doradztwo podatkowe (obejmujące podstawowe zagadnienia związane z funkcjonowaniem podmiotu gospodarczego),",
      "D.	doradztwo z zakresu prawa pracy (m.in.: sporządzanie umów, doradztwo związane z funkcjonowaniem zakładu pracy)",
    ],
    secondTable: [
      "2.	gwarantowana ilość czasu w miesiącu, który Kancelaria przeznacza na obsługę prawną firmy;",
      "3.	możliwość konsultacji telefonicznych lub online w ramach pakietu usług;",
      "4.	stała wysokość honorarium Kancelarii, co pozwala kontrolować finanse w firmie;",
      "5.	stała opieka prawna Kancelarii, w wyniku której zaistniałe w firmie problemy prawne są rozwiązywane na bieżąco.",
    ],
  },
  {
    id: 11,
    title: "Pakiet „Biznes”",
    descriptionCard:
      "Powyższa oferta współpracy jest dedykowana dla firm funkcjonujących na rynku. Korzyści, które otrzymuje klient:",
    button: "Czytaj więcej",
    thead:
      "1.	Pakiet usług prawnych mających na celu wspieranie rozwoju firmy i zabezpieczanie jej interesów prawnych, tj.:",
    tbody: [
      "A.	pełna obsługa korporacyjna firmy (m.in. tworzenie dokumentów korporacyjnych, doradztwo w zakresie funkcjonowania),",
      "B.	przeprowadzenie audytu stosowania przepisów o ochronie danych osobowych („RODO”) w organizacji (m.in. przygotowanie raportu z audytu, pomoc w zapewnieniu zgodności z prawem przetwarzania danych osobowych w organizacji, przeprowadzanie szkoleń dla pracowników),",
      "C.	doradztwo z zakresu prawa pracy (m.in. tworzenie umów, doradztwo związane z funkcjonowaniem zakładu pracy),",
      "D.	sporządzenie umów cywilnoprawnych,",
      "E.	pomoc w windykacji należności firmy (m.in. prowadzenie negocjacji z kontrahentami, sporządzanie pism procesowych, reprezentacja klienta w sądzie)",
    ],
    secondTable: [
      "2.	możliwość konsultacji telefonicznych lub online w ramach pakietu usług;",
      "3.	gwarantowana ilość czasu w miesiącu, które Kancelaria przeznacza na obsługę prawną firmy;",
      "4.	stała wysokość honorarium Kancelarii, co pozwala kontrolować finanse w firmie;",
      "5.	stała opieka prawna Kancelarii, w wyniku której zaistniałe w firmie problemy prawne są rozwiązywane na bieżąco.",
    ],
  },
]
