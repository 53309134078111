export const COLORS = {
  mainBlue: "#082567",
  secondBlue: "#f7f7f7",
  darkerWhite: "#c2c9d9",
  white: "#fff",
}

export const GRADIENT = `linear-gradient(to top right, ${(COLORS.baby,
COLORS.lightWhite)})`

export const BORDER_RADIUS = "10px"
