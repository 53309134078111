import React, { useState, useEffect } from "react"
import { Navbar, Container, Nav } from "react-bootstrap"
import Logo from "../../images/white_svg.svg"
import logokirp from "../../images/logokirp.png"
import { Link } from "react-scroll"
import "./navigation.scss"

export default function Navigation() {
  const [isNavbarVisible, setNavbarVisibility] = useState(false)
  const [shouldBeTransity, setNavbarTransitiy] = useState(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  function handleScroll(e) {
    setNavbarVisibility(document.documentElement.scrollTop > 100)
    setNavbarTransitiy(document.documentElement.scrollTop > 300)
  }

  function getStateOfScroll() {
    const visible = isNavbarVisible ? "showNavbar" : "hideNavbar"
    const transity = shouldBeTransity ? "navbar-transition" : ""
    return [visible, transity]
  }
  return (
    <Navbar
      expanded={expanded}
      id="navbar"
      className={getStateOfScroll()}
      fixed="top"
      variant="dark"
      expand="xl"
    >
      <Container id="navigation-container">
        <div className="logo-containers">
          <Link
            onClick={() => setExpanded(false)}
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item logo-screen"
          >
            <Navbar.Brand className="navbarlogo">
              <img src={Logo} className="img-fluid" width="120" alt="logo" />
            </Navbar.Brand>
          </Link>
          <Link
            onClick={() => setExpanded(false)}
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item logo-screen"
          >
            <Navbar.Brand className="navbarlogo">
              <img
                src={logokirp}
                className="img-fluid"
                width="120"
                alt="logo"
              />
            </Navbar.Brand>
          </Link>
        </div>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />{" "}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <Link
              onClick={() => setExpanded(false)}
              activeClass="active"
              to="kancelaria"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav-item"
            >
              Kancelaria
            </Link>
            <Link
              onClick={() => setExpanded(false)}
              activeClass="active"
              to="specjalizacje"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className="nav-item"
            >
              Specjalizacje
            </Link>
            <Link
              onClick={() => setExpanded(false)}
              activeClass="active"
              to="opinie"
              spy={true}
              smooth={true}
              offset={-170}
              duration={500}
              className="nav-item"
            >
              Kwalifikacje i Referencje
            </Link>
            <a
              href="https://www.blog.jurczynskikancelaria.pl/"
              className="nav-item external"
            >
              Blog
            </a>
            <Link
              onClick={() => setExpanded(false)}
              activeClass="active"
              to="kontakt"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className="nav-item"
            >
              Kontakt
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
