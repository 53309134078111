import React from "react"
import { COLORS } from "../styles/constants"
import styled from "@emotion/styled"
import { FaAngleUp } from "react-icons/fa"
import { Link as RLink } from "react-scroll"
const FooterWrapper = styled.footer`
  background-color: #1a1a1a;
  padding: 45px 0 35px 0;
  border-bottom: 8px solid ${COLORS.mainBlue};

  #copyright {
    width: 100%;
    p {
      color: #8c8b8a;
      font-weight: 600;
      font-size: 15px;
      a {
      }
    }
  }
  .scroll-top {
    cursor: pointer;
    text-align: right;
    a {
      background-color: ${COLORS.mainBlue};
      padding: 25px;
      color: #fff;
      font-size: 25px;
    }
  }
`
export default function Footer() {
  return (
    <FooterWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div id="copyright">
              <p>
                Copyright &copy; 2021 - Kancelaria Radcy Prawnego - Paweł Jurczyński.
                All Rights Reserved
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="scroll-top">
              <RLink
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <FaAngleUp />
              </RLink>
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  )
}
