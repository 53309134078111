import React from "react"
import HeaderBg from "../images/header-bg.jpg"
import styled from "@emotion/styled"
import { COLORS } from "../styles/constants"
import { device } from "../styles/theme"
import Fade from "react-reveal/Fade"
import { Link } from "react-scroll"
import { FaAngleDown } from "react-icons/fa"

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background: ${COLORS.mainBlue};
  @media ${device.tablet} {
  }
  img {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    object-fit: contain;
    @media ${device.tablet} {
      height: 50vh;
    }
  }

  .header-overlay {
    // background-color: rgba(31, 31, 31, 0.5);
    min-height: 100%;
  }
`

const HeaderBtns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 28px;
  margin-top: -130px;
  @media ${device.mobile} {
    margin-bottom: 50px;
    margin-top: -35px;
  }
  .react-reveal {
    display: flex;
  }
  .btn-offer,
  .btn-offer:focus,
  .btn-offer:active,
  .btn-offer:visited {
    border-radius: 3px;
    border-color: #e3e7ef45;
    background: transparent;
    padding: 10px 30px 10px 30px;
    font-size: 19px;
    text-transform: uppercase;
    transition: all 0.5s;
    color: ${COLORS.white} !important;
  }

  .btn-offer:hover {
    background-color: ${COLORS.white};
    color: ${COLORS.mainBlue} !important;
    cursor: pointer;
  }

  .btn-more,
  .btn-more:focus,
  .btn-more:active,
  .btn-more:visited {
    margin-top: 10px;
    color: ${COLORS.white} !important;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 500;
    background: transparent;
    align-self: center;
    transition: all 0.5s;
    @media ${device.mobile} {
      margin-top: 10px;
    }
  }

  .btn-more:hover {
    color: #949090 !important;
    cursor: pointer;
  }

  a {
    display: flex;
    align-content: center;
    align-items: center;
  }
`

export default function Header() {
  return (
    <HeaderWrapper alt="background-header" id="home" className="header">
      <div className="header-overlay">
        <img src={HeaderBg} />

        <div className="col-md-12 text-center">
          <HeaderBtns>
            <Fade left>
              <Link
                to="specjalizacje"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="btn btn-offer"
              >
                Poznaj pełną ofertę
              </Link>
            </Fade>
            <Fade right>
              <Link
                to="kancelaria"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="btn btn-more"
              >
                Więcej <FaAngleDown style={{ marginLeft: "4px" }} />
              </Link>
            </Fade>
          </HeaderBtns>
        </div>
      </div>
    </HeaderWrapper>
  )
}
