import React from "react"
import styled from "@emotion/styled"
import { COLORS } from "../styles/constants"
import Fade from "react-reveal/Fade"
import { device } from "../styles/theme"
import {LightBox} from './lightbox/LightBox'

const TestmionalWrapper = styled.section`
  padding: 50px;

  #carousel-testimonial {
    height: 120px;
    @media ${device.mobile} {
      height: 140px;
    }
  }

  @media ${device.mobile} {
    padding: 25px;
  }

  background-color: ${COLORS.secondBlue};
  .testimonial-overlay {
    padding: 45px 0 190px;
  }
`

const HeaderTitle = styled.header`
  text-align: center;
  margin-bottom: 135px;
  color: #333;

  h2 {
    position: relative;
    text-align: center;
    color: #333;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    span {
      font-weight: 800;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: #1b3267;
      bottom: -20px;
      left: 50%;
      width: 60px;
      height: 1.7px;
      margin-left: -30px;
    }
  }
  p {
    color: #333;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    margin-top: 24px;
  }
`

export default function Testimonials() {
  return (
    <TestmionalWrapper id="opinie">
      <div className="testimonial-overlay">
        <HeaderTitle>
          <h2>Kwalifikacje i Referencje</h2>
        </HeaderTitle>
        <Fade down>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <LightBox />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </TestmionalWrapper>
  )
}
