import React, { useState } from "react"
import Logo from "../images/cooperation.jpg"
import styled from "@emotion/styled"
import { COLORS } from "../styles/constants"
import { Button, ButtonToolbar } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import { BusinessOffers, BusinessCard } from "../TextFiles/BusinessOffersText"
import { MyVerticallyCenteredModal } from "./modal/VerticallyCenteredModal"
import Card from "./card/Card"
import { ContainerCard } from "./card/Card"

const FeaturesInnerWrapper = styled.div`
  .my-btn {
    background-color: ${COLORS.mainBlue};
    :active {
      background-color: ${COLORS.mainBlue} !important;
    }
  }
  padding: 30px 0 70px 0;

  h3 {
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 700;
    color: #333;
    margin-left: 90px;
  }
  p {
    color: #333;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    padding: 5px;
  }
  .package {
    color: #333;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    text-align: center;
    margin-top: 15px;
    text-align: justify;
  }
  .left-feature-item {
    position: relative;
    margin-bottom: 65px;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-feature-item-icon {
      position: absolute;
      font-size: 40px;
      color: ${COLORS.mainBlue};
      left: 0;
      top: -13px;
      width: 90px;
      height: 90px;
      text-align: center;
    }
  }
`
const FeatureLaw = styled.div`
  img {
    width: 100%;
    box-shadow: -14px -12px 47px -2px rgba(0, 0, 0, 0.51);
    border-radius: 2%;
    margin-left: 30px;
  }
`

export default function BusinessOffer() {
  const [showModal, setModals] = useState({
    activeModal: null,
  })

  return (
    <section id="specjalizacje" className="businessOffer">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div
              className="section-title"
              style={{ paddingBottom: 0, marginTop: "50px" }}
            >
              <h2>Specjalizacje</h2>
              <p
                className="business-offer--paragraph"
                style={{ marginBottom: 0 }}
              >
                {
                  "Kancelaria Radcy Prawnego Paweł Jurczyński świadczy usługi prawne w następujących obszarach praktyki: "
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <FeaturesInnerWrapper>
        <div className="container">
          <div className="row">
            <div className="offset-md-1 col-md-7">
              {/* !-- LEFT ITEMS --! */}
              {BusinessOffers.map((element, index) => {
                return (
                  <Fade up cascade key={index}>
                    <div className="left-feature-item">
                      <div className="left-feature-item-icon">
                        {element.icon}
                      </div>
                      <h3>{element.title}</h3>
                      <ButtonToolbar>
                        <Button
                          className="btn-offer"
                          onClick={() => setModals({ activeModal: index })}
                        >
                          +
                        </Button>

                        <MyVerticallyCenteredModal
                          description={element.desc}
                          modalheading={element.title}
                          show={showModal.activeModal === index}
                          onHide={() => setModals({ activeModal: null })}
                        />
                      </ButtonToolbar>
                    </div>
                  </Fade>
                )
              })}
            </div>
            <div className="py-5 mt-5 col-md-4 d-none d-md-block">
              <FeatureLaw>
                <img src={Logo} alt="law" />
              </FeatureLaw>
            </div>
          </div>
          <p className="package offset-md-1">
            Kancelaria posiada w swojej ofercie pakiety usług prawnych dla
            przedsiębiorców. Jest to forma stałej współpracy klienta z
            Kancelarią, opartej na okresowym rozliczeniu ryczałtowym. Dzięki
            pakietom firma uzyskuje usługi prawne, które są dostosowane do jej
            potrzeb, w korzystnym dla niej rozliczeniu. Poniżej znajdują się dwa
            modelowe pakiety dedykowane dla start-upów oraz dla funkcjonujących
            firm.
          </p>
          <ContainerCard>
            <div className="row">
              {BusinessCard.map((element, index) => {
                return (
                  <>
                    <Card
                      key={element.id}
                      title={element.title}
                      descriptionCard={element.descriptionCard}
                      button={element.button}
                      onClick={() => setModals({ activeModal: element.id })}
                    />
                    <MyVerticallyCenteredModal
                      key={index}
                      description={element.descriptionDetails}
                      modalheading={element.title}
                      thead={element.thead}
                      tbody={element.tbody}
                      secondtable={element.secondTable}
                      show={showModal.activeModal === element.id}
                      onHide={() => setModals({ activeModal: null })}
                    />
                  </>
                )
              })}
            </div>
          </ContainerCard>
        </div>
      </FeaturesInnerWrapper>
    </section>
  )
}
